@mixin flex-centering{
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-margin{
  margin: 0 0 57px 0;
}

.button-wrapper{
  display: flex;
  justify-content: center;
}

.center-member{
  margin: 0 27px;
}

.about__video .button-wrapper{
  margin: 59px 0 0 0;
}

.about__team .button-wrapper{
  margin: 51px 0 0 0;
}

.special-title{
  padding: 0 20px;
  &::before{
    padding: inherit;
  }
}

.about{
  overflow-x: hidden;
  .basic-title{
    font-size: 74px;
    line-height: 86px;
    margin: 0 0 65px 0;
  }
  .our_brands{
    margin: 0;
    .brands{
      margin: 0;
    }
    h3{
      margin: 0 0 65px 0;
    }
  }
  .heading{
    background-image: url("../images/about_title.jpg");
  }
  &__services{
    margin: 176px 0 0 0;
  }
  &__agency{
    margin: 175px 0 0 0;
  }
  &__video{
    margin: 182px 0 0 0;
    .blue_btn{
      width: 229px;
      border-radius: 35px;
      font-size: 16px;
    }
    p{
      font-weight: 400;
      letter-spacing: -0.2px;
      text-align: center;
      margin: 0 0 41px 0;
    }
  }
  &__team{
    margin: 178px 0 0 0;
    .blue_btn{
      min-width: 261px;
      border-radius: 34px;
      text-align: center;
    }
  }
  &__team-members{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  &__member{
    position: relative;
    flex: 0 1 31.3%;
    margin: 1% 1%;
    img{
      height: 100%;
      width: 100%;
    }
  }
  .partners__block{
    margin: 183px 0 128px 0;
  }
}

.about__services, .about__agency{
  p{
    &:last-child{
      margin: 0;
    }
  }
}

.about__member:focus img,
.about__member:hover img {
  z-index: -1;
}

.about__member:hover .gallery__flex,
.about__member:focus .gallery__flex {
  z-index: 0;
  opacity: 1;
}

.about__member:focus .gallery__flex:after,
.about__member:hover .gallery__flex:after {
  max-width: 1000px;
}

.about__member:focus .gallery__flex:before,
.about__member:hover .gallery__flex:before {
  max-height: 1000px;
}

.about__member:focus .gallery__flex-text:after,
.about__member:hover .gallery__flex-text:after {
  top: 100%;
}

.about__member:focus .gallery__flex-text:before,
.about__member:hover .gallery__flex-text:before {
  bottom: 100%;
}

.member__name{
  font-family: Roboto;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: -0.73px;
  line-height: 48px;
}

.member__position{
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.67px;
  line-height: 44px;
}


@media (max-width: 1440px) {
  .about{
    .basic-title{
      font-size: 67px;
      margin: 0 0 60px 0;
    }
    .breadcrumbs{
      margin: 34px 0 0 0;
    }
    .partners__block{
      margin: 133px 0 123px 0;
    }
    .our_brands{
      .basic-title{
        margin: 0 0 60px 0;
      }
    }
  }
  .about__header--inner{
    min-height: 245px;
  }
  .about__title{
    font-size: 67px;
  }
  .about__agency{
    margin: 132px 0 0 0;
  }
  .about__video{
    margin: 138px 0 0 0;
    p{
      margin: 0 0 37px 0;
    }
    .button-wrapper{
      margin: 57px 0 0 0;
    }
    .blue_btn{
      width: 192px;
      height: 58px;
    }
  }
  .about__services {
    margin: 134px 0 0 0;
  }
  .about__team{
    margin: 142px 0 0 0;
    .button-wrapper{
      margin: 58px 0 0 0;
    }
  }
}

@media (max-width: 1024px) {
  .about{
    .breadcrumbs{
      margin: 32px 0 0 0;
      a{
        font-size: 16px;
        letter-spacing: -0.15px;
      }
      li{
        font-size: 16px;
        letter-spacing: -0.15px;
      }
    }
    .basic-title{
      font-size: 49px;
      line-height: 44px;
      margin: 0 0 55px 0;
    }
    .our_brands{
      .basic-title{
        margin: 0 0 55px 0;
      }
    }
    .partners__block{
      margin: 103px 0 100px 0;
    }
  }
  .about__header--inner{
    min-height: 194px;
  }
  .about__title{
    font-size: 54px;
  }
  .about__services{
    margin: 112px 0 0 0;
  }
  .about__agency{
    margin: 128px 0 0 0;
  }
  .about__video{
    margin: 116px 0 0 0;
    p{
      margin: 0 0 35px 0;
    }
    .button-wrapper{
      margin: 45px 0 0 0;
    }
  }
  .about__team{
    margin: 110px 0 0 0;
    .button-wrapper{
      margin: 77px 0 0 0;
    }
  }
  .center-member{
    margin: 0 22px;
  }
  .about__team .blue_btn{
    min-width: 209px;
    width: 228px;
    height: 60px;
    font-size: 12px;
    padding: 5px 20px;
  }
  .about__brands{
    margin: 100px 0 0 0;
  }
  .about__brand{
    flex: 0 1 50%;
  }
  .brand{
    padding: 0 10px;
  }
  .member__name{
    font-size: 21px;
    line-height: 38.17px;
    letter-spacing: -0.73px;
  }
  .member__position{
    font-size: 20px;
    line-height: 34.99px;
    letter-spacing: -0.69px;
  }
}

@media (max-width: 768px) {
  .about{
    .breadcrumbs{
      margin: 24px 0 0 0;
      a{
        font-size: 14px;
        letter-spacing: -0.17px;
      }
      li{
        font-size: 14px;
        letter-spacing: -0.17px;
      }
    }
    .basic-title{
      font-size: 38px;
      line-height: 35.71px;
      margin: 0 0 40px 0;
    }
    .our_brands{
      .basic-title{
        margin: 0 0 40px 0;
      }
    }
    .partners__block{
      margin: 83px 0 62px 0;
    }
  }
  .about__header--inner{
    min-height: 142px;
  }
  .about__title{
    font-size: 48px;
  }
  .about__services {
    margin: 87px 0 0 0;
    h3{
      margin: 0 0 41px 0;
    }
  }
  .about__agency{
    margin: 77px 0 0 0;
    h3{
      margin: 0 0 42px 0;
    }
  }
  .about__video{
    margin: 91px 0 0 0;
    h3{
      margin: 0 0 42px 0;
    }
    p{
      margin: 0 0 31px 0;
    }
    .button-wrapper{
      margin: 38px 0 0 0;
    }
  }
  .about__team{
    margin: 84px 0 0 0;
    h3{
      margin: 0 0 44px 0;
    }
    .button-wrapper{
      margin: 37px 0 0 0;
    }
    .blue_btn{
      width: 218px;
    }
  }
  .member__name{
    font-size: 16px;
    line-height: 30.25px;
  }
  .member__position{
    font-size: 16px;
    line-height: 27.73px;
  }
  .about__brands{
    margin: 62px 0 0 0;
    h3{
      margin: 0 0 58px 0;
    }
  }
  .brand{
    padding: 0px 20px;
    img{
      max-width: 100%;
      height: auto;
    }
  }
  .about__team-members{
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
  .about__member{
    margin: 0;
    img{
      display: block;
    }
  }
}

@media (max-width: 425px) {
  .about{
    .basic-title{
      font-size: 35px;
      margin: 0 0 35px 0;
    }
    .breadcrumbs{
      a{
        letter-spacing: -0.18px;
      }
      li{
        letter-spacing: -0.18px;
      }
    }
    .our_brands{
      .basic-title{
        margin: 0 0 35px 0;
      }
    }
    .partners__block{
      margin: 79px 0 75px 0;
    }
  }
  .about__header--inner{
    min-height: 115px;
  }
  .about__title{
    font-size: 40px;
  }
  .about__services {
    margin: 55px 0 0 0;
    h3{
      margin: 0 0 54px 0;
    }
  }
  .about__agency{
    margin: 81px 0 0 0;
    h3{
      margin: 0 0 49px 0;
    }
  }
  .about__video{
    margin: 81px 0 0 0;
    h3{
      margin: 0 0 39px 0;
    }
    p{
      margin: 0 0 27px 0;
    }
    .button-wrapper{
      margin: 34px 0 0 0;
    }
    .blue_btn{
      width: 161px;
      height: 50px;
      font-size: 11px;
    }
  }
  .about__team{
    margin: 79px 0 0 0;
    h3{
      margin: 0 0 45px 0;
    }
    .button-wrapper{
      margin: 43px 0 0 0;
    }
    .blue_btn{
      width: 182px;
      min-width: 135px;
      height: 50px;
      font-size: 10px;
    }
  }
  .member__name{
    display: block;
    font-size: 22px;
    line-height: 31.86px;
  }
  .member__position{
    font-size: 18px;
    line-height: 30.06px;
  }
  .about__brands{
    margin: 75px 0 0 0;
    h3{
      margin: 0 0 48px 0;
    }
  }
  .about__brands-list{
    flex-direction: column;
  }
}


